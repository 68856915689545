import { AppEnv, ClubApp, Environment } from "@aaa/emember/types"

/**
 * copy/pasted from firebase project settings
 */
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBbhUgnr3v79Pc63IB6DkTnlx8rFmTk8c8",
  authDomain: "aaa-colorado-2.firebaseapp.com",
  databaseURL: "https://aaa-colorado-2-default-rtdb.firebaseio.com",
  projectId: "aaa-colorado-2",
  storageBucket: "aaa-colorado-2.appspot.com",
  messagingSenderId: "852531056326",
  appId: "1:852531056326:web:95ac19d53eccde905291c7",
  measurementId: "G-8ECRDJ43D5",
}

export const environment: Environment = {
  production: true,
  emulator: false,
  ngServe: false,
  appEnv: AppEnv.TEST,
  cloudFunctionsURL: "https://test.colorado.millisite.com",
  cloudStorageURL: `https://storage.googleapis.com/${firebaseConfig.storageBucket}`,
  clubId: ClubApp.Colorado,
  firebaseConfig: firebaseConfig,
  SMARTY_WEBSITE_KEY: "",
  paymentConfig: {
    cyberSourceSessionTimeout: 60 * 1000 * 10, // ten minutes
  },
  aaaRestWsUrl: "https://ww2.aaa.com",
  baseHref: "https://test.colorado.millisite.com/",
  zipcode: "80111",
}
